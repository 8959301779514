.sd-body {
  width: 100%;

  .sd-body__page {
    min-width: calc(300px + 6 * #{$base-unit});
  }

  .sd-body__timer {
    padding: calcSize(2) calcSize(2) 0;
    box-sizing: border-box;
  }

  &.sd-body--static {
    max-width: calcSize(84);
    margin-left: auto;
    margin-right: auto;
    .sd-body__timer,
    .sd-body__navigation,
    .sd-body__page {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.sd-body--responsive {
    max-width: initial;

    .sd-body__timer,
    .sd-body__navigation {
      padding: calcSize(2) var(--sd-page-vertical-padding);
    }
    &.sd-body--with-timer {
      max-width: calc(100% + var(--sd-timer-size) * ( -1  - 64 / 144 ) + 6 * #{$base-unit});
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.sd-root-modern--mobile .sd-body.sd-body--with-timer.sd-body--responsive {
  max-width: initial;
  margin-left: 0;
  margin-right: 0;
}

.sd-body__navigation.sd-action-bar {
  box-sizing: border-box;
  padding: calcSize(2) calcSize(2);
  flex-wrap: wrap;
  gap: calcSize(2);
}

.sd-body__progress {
  margin-bottom: calcSize(4);
}
.sd-body__progress--top {
  position: sticky;
  top: 0;
  z-index: 50;
}

.sd-body--empty {
  min-height: 400px;
  text-align: center;
  padding-top: 180px;
  box-sizing: border-box;
}
.sd-root_background-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
