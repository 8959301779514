@import "./blocks/mixins.scss";
@import "../common-styles/common-styles.scss";

@import "blocks/sd-element.scss";
@import "blocks/sd-question.scss";
@import "blocks/sd-input.scss";
@import "blocks/sd-comment.scss";
@import "blocks/sd-panel.scss";
@import "blocks/sd-signaturepad.scss";
@import "blocks/sd-checkbox.scss";
@import "blocks/sd-matrixdynamic.scss";
@import "blocks/sd-table.scss";
@import "blocks/sd-page.scss";
@import "blocks/sd-row.scss";
@import "blocks/sd-title.scss";
@import "blocks/sd-description.scss";
@import "blocks/sd-item.scss";
@import "blocks/sd-selectbase.scss";
@import "blocks/sd-checkbox.scss";
@import "blocks/sd-radio.scss";
@import "blocks/sd-visuallyhidden.scss";
@import "blocks/sd-matrix.scss";
@import "blocks/sd-rating.scss";
@import "blocks/sd-ranking.scss";
@import "blocks/sd-dropdown.scss";
@import "blocks/sd-tagbox.scss";
@import "blocks/sd-imagepicker.scss";
@import "blocks/sd-image.scss";
@import "blocks/sd-html.scss";
@import "blocks/sd-expression.scss";
@import "blocks/sd-progress.scss";
@import "blocks/sd-boolean.scss";
@import "blocks/sd-paneldynamic.scss";
@import "blocks/sd-file.scss";
@import "blocks/sd-hidden.scss";
@import "blocks/sd-navigation.scss";
@import "blocks/sd-button.scss";
@import "blocks/sd-body.scss";
@import "blocks/sd-multipletext.scss";
@import "blocks/sd-action.scss";
@import "blocks/sd-action-bar.scss";
@import "blocks/sd-context-btn.scss";
@import "blocks/sd-completedpage.scss";
@import "blocks/sd-progress-buttons.scss";
@import "blocks/sd-progress-toc.scss";
@import "blocks/sd-list.scss";
@import "blocks/sd-timer.scss";
@import "../components-container.scss";
@import "../signaturepad.scss";
@import "./defaultV2.m600.scss";

body {
  --sv-defaultV2-mark: true;
}

.sd-root-modern {
  -webkit-font-smoothing: antialiased;
  --sd-mobile-width: 600px;
  --sd-imagepicker-gap: 16px;
  --sd-timer-size: calc(18 * #{$base-unit});
  width: 100%;
  font-family: var(--font-family, $font-family);
  background-color: $background-dim;
  position: relative;

  form {
    z-index: 1;
    position: relative;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: $border transparent;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 32px;
    background-color: $border;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    border: 2px solid rgba(0, 0, 0, 0);
    background-color: $foreground-light;
  }
}

.sd-container-modern {
  margin-bottom: calcSize(10);
}

.sd-root-modern--mobile {
  --sd-timer-size: calc(9 * #{$base-unit});
}

.sv-popup {
  .sv-popup__scrolling-content {
    scrollbar-width: thin;
    scrollbar-color: $border transparent;

    *,
    & {
      &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: $background;
      }

      &::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 32px;
        background-color: $border;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb:hover {
        border: 2px solid rgba(0, 0, 0, 0);
        background-color: $foreground-light;
      }
    }
  }
}

.sv-popup.sv-popup--dropdown {
  .sv-popup__shadow {
    box-shadow: 0px calcSize(1) calcSize(2) rgba(0, 0, 0, 0.1);
  }

  .sv-popup__body-content {
    background-color: $background;
    padding: calcSize(1) 0;
  }
}

.sv-popup.sv-popup--modal {
  .sv-popup__body-content {
    box-shadow: $shadow-large;
    background-color: $background-dim-light;
  }

  .sv-popup__body-footer {
    padding-bottom: 2px;
  }
}

.sv-popup.sv-popup--overlay {
  .sv-popup__body-content {
    background-color: $background-dim;
  }
}
